import getClientEnvironment from '@config/getClientEnvironment';

export default () => {
  const environment = getClientEnvironment();
  let workspaceId;
  if (environment === 'production') {
    return (workspaceId = 'fvkfk9eu');
  } else {
    return (workspaceId = 'bpmwa23h');
  }
};
