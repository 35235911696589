import React, { createContext, useContext, useState } from 'react';

import { type AlertColor, Grow, type GrowProps, Slide, type SlideProps } from '@mui/material';

import SimpleSnackbar from '@client/components/general/SimpleSnackbar';

type SnackbarContextProps = {
  handleSnackbarOpen: (
    message: string,
    severity?: AlertColor,
    autoHideDuration?: number,
    transition?: 'slide' | 'grow',
  ) => void;
  handleSnackbarClose: () => void;
  toggleSnackbar: () => void;
};

const getTransition = (transition?: 'slide' | 'grow') => {
  if (!transition) return undefined;
  if (transition === 'slide') {
    return (props: SlideProps) => <Slide {...props} direction='right' />;
  }

  return (props: GrowProps) => <Grow {...props} />;
};

const SnackbarContext = createContext({} as SnackbarContextProps);

export const useSnackbarContext = () => useContext(SnackbarContext);

const SnackbarContextProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [isShowing, setIsShowing] = useState(false);
  const [message, setMessage] = useState('');
  const [autoHideTimer, setAutoHideTimer] = useState(6000);
  const [messageSeverity, setMessageSeverity] = useState<AlertColor>('success');
  const [transition, setTransition] = useState<'slide' | 'grow'>('slide');

  const handleSnackbarClose = () => {
    setIsShowing(false);
  };
  const handleSnackbarOpen = (
    message: string,
    severity: AlertColor = 'success',
    autoHideDuration: number = 6000,
    transition: 'slide' | 'grow' = 'slide',
  ) => {
    setIsShowing(true);
    setMessage(message);
    setMessageSeverity(severity);
    setAutoHideTimer(autoHideDuration);
    setTransition(transition);
  };

  const toggleSnackbar = () => {
    setIsShowing(state => !state);
  };
  return (
    <SnackbarContext.Provider
      value={{
        handleSnackbarClose,
        handleSnackbarOpen,
        toggleSnackbar,
      }}>
      {children}
      {isShowing && (
        <SimpleSnackbar
          TransitionComponent={getTransition(transition)}
          isShowing={isShowing}
          message={message}
          onClose={handleSnackbarClose}
          autoHideDuration={autoHideTimer}
          severity={messageSeverity}
        />
      )}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
