import * as React from 'react';

import MuiAlert, { type AlertColor, type AlertProps } from '@mui/material/Alert';
import Snackbar, { type SnackbarProps } from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import { useClientBrowser } from '../hooks/useClientBrowser';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
));

type Snackbar = SnackbarProps & {
  message: string;
  isShowing: boolean;
  autoHideDuration?: number;
  severity?: AlertColor;
  onClose: () => void;
};

export default ({
  isShowing,
  onClose,
  message,
  severity = 'success',
  autoHideDuration = 6000,
  ...rest
}: Snackbar) => {
  const { isMobile } = useClientBrowser();
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Stack spacing={2} sx={{ width: isMobile ? '50%' : '100%' }}>
      <Snackbar
        {...rest}
        open={isShowing}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%', color: 'white' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
