import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { getIsPublicPath } from '@client/constants/navigation';

export const usePreviousProtectedRoute = () => {
  const { events, asPath } = useRouter();
  const previousProtectedRouteRef = useRef<string | null>(null);

  useEffect(() => {
    events.on('routeChangeComplete', url => {
      // console.log({ asPath }, { basePath });
      if (!getIsPublicPath(asPath) && asPath !== '/') {
        // console.log(asPath, " is a private url");

        previousProtectedRouteRef.current = asPath;
      }
    });
  }, [asPath]);

  return previousProtectedRouteRef.current;
};
