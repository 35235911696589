import { useAuth } from '@client/context/auth/AuthContext';
import { Mixpanel } from '@config/Mixpanel';
import {
  type AdviceEventNames,
  type AuthEventNames,
  type CustomMixpanelProperties,
  type InvoiceTypeEventNames,
  type MixpanelEventNames,
} from '@customTypes/analytics';

import mixpanel from 'mixpanel-browser';

export const useMixpanelTracking = () => {
  const { user, isUserIdentified } = useAuth();

  const defaultProperties = {
    user_id: user?.uid,
    user_role: 'dossier',
  };

  const trackMixpanelEvent = (
    event: MixpanelEventNames,
    customProperties?: CustomMixpanelProperties,
  ) => {
    const properties = { ...defaultProperties, ...customProperties };

    Mixpanel.queue(() => {
      mixpanel.track(event, properties);
    });
  };

  const trackInvoiceMixpanelEvent = (eventType: InvoiceTypeEventNames) => {
    Mixpanel.queue(() => {
      mixpanel.track('invoice_event', { eventType, ...defaultProperties });
    });
  };

  const trackPageView = (url: URL | string) => {
    Mixpanel.queue(() => {
      mixpanel.track('page_view', {
        url,
        defaultProperties,
      });
    });
  };

  const identifyUser = (userId: string) => {
    if (!userId || isUserIdentified) return;
    Mixpanel.queue(() => {
      mixpanel.identify(userId);
    });
  };

  const trackAuthMixpanelEvent = (
    eventType: AuthEventNames,
    customProperties?: CustomMixpanelProperties,
  ) => {
    const properties = { ...defaultProperties, ...customProperties };

    Mixpanel.queue(() => {
      mixpanel.track('auth_event', { eventType, ...properties });
    });
  };

  const resetUser = () => {
    mixpanel.reset();
  };

  const register = (properties: CustomMixpanelProperties) => {
    Mixpanel.queue(() => {
      mixpanel.register(properties);
    });
  };

  const trackAdviceEvent = (
    eventType: AdviceEventNames,
    customProperties?: CustomMixpanelProperties,
  ) => {
    const properties = { ...defaultProperties, ...customProperties };

    Mixpanel.queue(() => {
      mixpanel.track('advice_event', { eventType, ...properties });
    });
  };
  return {
    trackMixpanelEvent,
    register,
    trackInvoiceMixpanelEvent,
    trackPageView,
    identifyUser,
    resetUser,
    trackAuthMixpanelEvent,
    trackAdviceEvent,
  };
};
