// import errorHandler from '@server/errorHandler'
// import handleLog from '@server/handleLog'
import { type Environment } from '@customTypes/auth';
import errorHandler from '@server/logger/errorHandler';

export default (environment: Environment) => {
  const functionName = 'getFirebaseConfig';

  try {
    // console.log(`${functionName} running in ${environment}`);
    if (environment === 'production') {
      return {
        apiKey: 'AIzaSyDADB5OCoQbt7OxrveMcUywWSk5xpIJL_c',
        authDomain: 'zazu-production.firebaseapp.com',
        projectId: 'zazu-production',
        storageBucket: 'zazu-production.appspot.com',
        messagingSenderId: '63284183901',
        appId: '1:63284183901:web:e5801698c14e15d5195eb4',
        measurementId: 'G-6VL05Q0PFG',
      };
    } else if (environment === 'development' || environment === 'preview') {
      return {
        apiKey: 'AIzaSyAxBe-G1GFE4lp6q-wLW8KyxT7LDFbymvU',
        authDomain: 'zazu-develop.firebaseapp.com',
        projectId: 'zazu-develop',
        storageBucket: 'zazu-develop.appspot.com',
        messagingSenderId: '90967042329',
        appId: '1:90967042329:web:effa385318f0e5c2413d88',
      };
    }
  } catch (error: any) {
    console.error(`${functionName} - environment ${environment} not recognized`);
    throw errorHandler(error);
  }
};
