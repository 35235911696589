/* eslint-disable @typescript-eslint/consistent-type-definitions */

import { red } from '@mui/material/colors';
import {
  createTheme,
  type PaletteColor,
  type PaletteColorOptions,
  responsiveFontSizes,
  type Theme,
} from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    gradient: true;
    success: true;
  }
}

declare module '@mui/material/styles' {
  interface Pallete {
    gradientPrimary: PaletteColor;
    gradientSecondary: PaletteColor;
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    gradientPrimary?: PaletteColorOptions;
    gradientSecondary?: PaletteColorOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    chip: true;
  }

  interface TypographyPropsColorOverrides {
    gradientPrimary: true;
    gradientSecondary: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    gradientPrimary?: true;
    gradientSecondary?: true;
  }
}

export const colors = {
  background: {
    default: '#F9FBFD',
    secondaryLight: '#FFF3E0',
    paper: '#fff',
  },
  primary: {
    main: '#0C6FF9', // this is a blue color.
    dark: '#2738C7',
    light: '#50B3FF',
    contrastText: '#fff',
  },
  gradientPrimary: {
    main: '-webkit-linear-gradient(90deg, #4224BA 0%, #2A81FB 100%)',
    contrastText: '#fff',
  },
  gradientSecondary: {
    main: '-webkit-linear-gradient(90deg, #F8B683 0%, #F39226 41.15%, #F56600 73.44%)',
    contrastText: '#fff',
  },

  secondary: {
    main: '#FF9800', // this is an orange color.
    dark: '#EF6C00',
    light: '#FFB74D',
    contrastText: '#fff',
  },
  error: {
    main: red.A400,
  },
  info: {
    main: '#53ac6c',
  },
  success: {
    main: '#22BC1F',
    dark: '#209E3C',
    light: '#9EF09C',
    contrastText: '#fff',
  },
  grey: {
    '500': '#666666',
  },
  warning: {
    main: '#F44336',
    dark: '#D32F2F',
    light: '#EF9A9A',
  },
  gradient: {
    primary: '-webkit-linear-gradient(90deg, #4224BA 0%, #2A81FB 100%)',
    secondary: '-webkit-linear-gradient(135deg, #F8B683 0%, #F39226 41.15%, #F56600 73.44%)',
  },
  component: {
    // paperBorderColor: "#E7EEF8", //old
    paperBorderColor: '#d3e0f2',
    inputBorderColor: '#F2F2F2',
    inputBorderColorHover: '#BABABA',
    contrastingBlue: '#EDF4FB',
    paperformBackground: '#f9fbfd',
    adviceChatboxAssistantBubble: '#F0F0F0',
  },
};

export let theme: Theme = createTheme({
  typography: {
    htmlFontSize: 14,
    fontFamily: "'Poppins', sans-serif",
    h1: { fontSize: '3rem', fontWeight: 'bold' },
    h2: { fontSize: '2.5rem', fontWeight: 'bold' },
    h3: { fontSize: '2rem', fontWeight: 'bold' },
    h4: { fontSize: '1.5rem', fontWeight: 'bold' },
    h5: { fontSize: '1.3rem', fontWeight: 'bold' },
    h6: { fontSize: '1.2rem', fontWeight: 'bold' },
    // used for titles in the forms
    subtitle1: { fontWeight: 600, fontSize: '1rem' },
    // define a subtitle2
    subtitle2: { fontSize: '0.9rem', fontWeight: 400 },
    // regular body text
    body1: { fontSize: '1rem', fontWeight: 400 },
    body2: {
      fontSize: '1rem',
      fontWeight: 500,
      color: colors.grey[500],
    },
  },

  // ---------- Global colors ----------
  palette: {
    background: colors.background,
    primary: colors.primary,
    secondary: colors.secondary,
    error: colors.error,
    warning: colors.warning,
    info: colors.info,
    success: colors.success,
    grey: colors.grey,
    gradientPrimary: colors.gradientPrimary,
    gradientSecondary: colors.gradientSecondary,
  },

  shape: {
    borderRadius: 12,
  },

  // ---------- Global components styling ----------
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
          '&::nth-of-type(1)': {
            minHeight: '100vh',
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: 'p',
          subtitle2: 'p',
        },
      },
      styleOverrides: {
        root: {
          variantMapping: {
            subtitle1: 'p',
            subtitle2: 'p',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 5,
        },
      },
    },
    // ---------- Navbar styling ----------
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: 'none',
          borderBottom: `1px solid ${colors.component.paperBorderColor}`,
          borderLeft: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          borderRight: 'none',
        },
        paper: {
          border: 'none',
          borderRight: `1px solid ${colors.component.paperBorderColor}`,
        },
      },
    },
    // ---------- Global button styling ----------
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            backgroundColor: colors.primary.dark,
            color: colors.background.paper,
          },
          '&:hover:not(.Mui-selected)': {
            backgroundColor: colors.component.contrastingBlue,
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.primary.dark,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          boxShadow: 'none',
          textTransform: 'none',
          textAlign: 'center',
          padding: '12px 24px',
          height: 52,
          borderRadius: '25px',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          border: `2px solid`,
          ':hover': {
            border: `2px solid`,
          },
        },
        contained: {
          border: 'none',
        },
      },
      // ---------- Linear gradiant custom button variant ----------
      variants: [
        {
          props: { variant: 'gradient' },
          style: {
            color: 'white',
            background: `linear-gradient(45deg, ${colors.primary.dark}, ${colors.primary.main})`,
            border: '1px none transparent',

            ':hover': {
              background: `linear-gradient(45deg, ${colors.primary.main}, ${colors.primary.dark})`,
              transitionDuration: '400ms, 400ms, 400ms, 400ms, 400ms',
              transition:
                'background-color 400ms ease, border-color 400ms ease, transform 400ms ease, box-shadow 400ms ease, -webkit-transform 400ms ease',
            },
            ':disabled': {
              background: colors.component.inputBorderColorHover,
            },
          },
        },
        {
          props: { variant: 'success' },
          style: {
            background: colors.success.main,
            color: 'white',
            ':hover': {
              background: colors.success.dark,
              transitionDuration: '400ms, 400ms, 400ms, 400ms, 400ms',
              transition:
                'background-color 400ms ease, border-color 400ms ease, transform 400ms ease, box-shadow 400ms ease, -webkit-transform 400ms ease',
            },
            ':disabled': {
              background: colors.component.inputBorderColorHover,
            },
          },
        },
      ],
    },
    // ---------- General UI div elements - paper, card, etc ----------
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: `1px solid ${colors.component.paperBorderColor}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.component.paperBorderColor}`,
          boxShadow: 'none',
        },
      },
    },

    // ---------- Global form input styling ----------
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: '1.1rem',
        },
        root: {
          color: colors.grey[500],
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.component.paperBorderColor}`,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${colors.primary.dark}`,
            },
          },
          '&:hover:not(.Mui-focused)': {
            '&:hover:not(.Mui-disabled)': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors.component.inputBorderColorHover}`,
              },
            },
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${colors.error.main} !important`,
            },
          },
          // "&:read-only": {
          //   border: `1px solid ${colors.error.main} !important`,
          // },
          // "&.Mui-disabled": {
          //   "& .MuiOutlinedInput-notchedOutline": {
          //     border: `1px solid ${colors.primary.dark}`,
          //   },
          // },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          // "&.MuiInputLabel-shrink": {
          //   "&.Mui-disabled": {
          //     color: colors.primary.dark,
          //   },
          // },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        // size:"small"
        // placeholder: "blue",
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          // "&.Mui-focused": {
          //   fontSize: "1rem",
          // },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',

          '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },

          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: colors.component.inputBorderColor,
          },
          '& .MuiDataGrid-row--borderBottom': {
            backgroundColor: `transparent !important`,
          },
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 12,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.component.contrastingBlue,
          },
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);
