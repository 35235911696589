// Import the functions you need from the SDKs you need
import { initializeFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import getClientEnvironment from './getClientEnvironment';
import getFirebaseConfig from './getFirebaseConfig';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const environment = getClientEnvironment();
// Your web app's Firebase configuration
const firebaseConfig = getFirebaseConfig(environment);
if (!firebaseConfig) {
  throw new Error('Something went wrong with the firebaseConfig');
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Initiliaze firestore
export const firestore = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
export const storage = getStorage(app);
export const db = getFirestore(app);
