import { type Environment } from '@customTypes/auth';

export default () => {
  const functionName = 'getClientEnvironment';
  const uncheckedEnvironemnt = process.env.NEXT_PUBLIC_ENVIRONMENT as Environment;
  if (
    !(
      uncheckedEnvironemnt == 'development' ||
      uncheckedEnvironemnt == 'production' ||
      uncheckedEnvironemnt == 'preview'
    )
  ) {
    console.error(functionName, 'no client environment found!');
    throw new Error('no client environment found');
  } else {
    const environment: Environment = uncheckedEnvironemnt;
    return environment;
  }
};
