var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bc9a8942ddfcbfcfe5cb729d92d4bbfc22b4d461"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

let ENV;
if (
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'preview' ||
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
) {
  ENV = 'development';
}
if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
  ENV = 'production';
}

Sentry.init({
  dsn: 'https://7d91b9773cdc455291b2c609c5220b62@o468354.ingest.sentry.io/6376527',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.5,
  environment: ENV,
});
