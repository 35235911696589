import mixpanel from 'mixpanel-browser';

import getClientEnvironment from './getClientEnvironment';

const clientEnvironment = getClientEnvironment();
const isProduction = clientEnvironment === 'production';
const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '043dbd96b4e4cf41ab8b2a629aacdc31';
let isInitialized = false;
let initQueue: Array<() => void> = [];

const processInitQueue = () => {
  while (initQueue.length > 0) {
    const queuedFunction = initQueue.shift();
    queuedFunction && queuedFunction();
  }
};

!isInitialized &&
  mixpanel.init(mixpanelToken, {
    debug: !isProduction,
    api_host: 'https://api-eu.mixpanel.com',
    ignore_dnt: true, // Stands for ignore-do-not-track
    loaded: mixpanel => {
      // console.log("Mixpanel loaded");
      isInitialized = true;
      processInitQueue();
    },
  });

export const Mixpanel = {
  isInitialized: () => isInitialized,
  queue: (func: () => void) => {
    if (!isInitialized) {
      // console.log("Mixpanel: queueing function for later execution");
      initQueue.push(func);
    } else {
      // console.log("Mixpanel: executing function");
      func();
    }
  },
};
