import { useEffect, useState } from 'react';

export const useClientBrowser = () => {
  const [agent, setAgent] = useState<string | null>(null);
  const isMobile = !!(agent?.match(/Android/i) || agent?.match(/IPhone/i));

  useEffect(() => {
    setAgent(navigator.userAgent);
  }, []);

  return {
    agent,
    isMobile,
  };
};
